<template>
  <VueFinalModal name="rakeback" v-bind="$attrs" classes="modal-container" content-class="modal-content"
    @before-open="onBeforeOpen">
    <div id="telegramModal" class="box-modal rake-modal">
      <div class="box-modal_close arcticmodal-close" @click.prevent="$vfm.hide('rakeback')">
        <img class="close" src="/icons/close.svg" alt="" />
      </div>
      <div class="title">Rakeback</div>
      <div class="rake__grid">
        <div class="rake__grid-item rake__grid-item--active">
          <div class="rake__grid-item-title">BASE</div>
          <div class="rake__grid-item-separate"></div>
          <div class="rake__grid-item-text">1% {{ $t("modal.rakeback.bets") }}</div>
        </div>
      </div>
      <div class="rake__alert">
        <div class="rake__alert-title">{{ $t("modal.rakeback.q1") }}</div>
        {{ $t("modal.rakeback.q1_a") }}
      </div>
      <div class="rake__alert">
        <div class="rake__alert-title">{{ $t("modal.rakeback.q2") }}</div>
        {{ $t("modal.rakeback.q2_a") }}
      </div>
      <div class="rake__alert">
        <div class="rake__alert-title">{{ $t("modal.rakeback.q3") }}</div>
        {{ $t("modal.rakeback.q3_a") }}
      </div>
      <div class="rake__btns">
        <button type="button" class="rake__btn-primary" @click="onClose">{{ $t("modal.rakeback.submit") }}</button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal, $vfm } from "vue-final-modal";

const statuses = ref([]);
const userStatus = ref({});
const { $axios } = useNuxtApp();

const initStatuses = async () => {
  await $axios.$get("/bonus/statuses/init").then(({ response: { status, statuses: sx } }) => {
    if (status) {
      statuses.value = sx;
      userStatus.value = status;
    }
  });
};

const onBeforeOpen = async () => {
  // await initStatuses();
};

const localePath = useLocalePath()

const onClose = () => {
  $vfm.hide("rakeback");
  const router = useRouter();
  router.push(localePath("/statuses"));
};
</script>

<style lang="scss">
.rake {
  &__grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 10px;
    margin-bottom: 16px;

    @media screen and (max-width: 550px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &-item {
      background: #f9f8ff;
      border: 1px solid #ece9fd;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 14px;

      &-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;

        color: #000000;
        text-transform: uppercase;
      }

      &-separate {
        opacity: 0.15;
        border: 1px solid #a9a4c2;
        margin: 10px auto;
        height: 1px;
        width: 51.28%;
      }

      &-text {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        text-align: center;

        color: #8c85b0;
      }

      &--active {
        background: linear-gradient(180deg, #a2bdff 0%, #6b38ff 100%);
        border: none;

        .rake__grid-item-title,
        .rake__grid-item-text {
          color: #ffffff;
        }

        .rake__grid-item-separate {
          opacity: 0.15;
          border: 1px solid #ffffff;
        }

        .rake__grid-item-text {
          opacity: 0.65;
        }
      }
    }
  }

  &__alert {
    &+& {
      margin-top: 11px;
    }

    background: #ffffff;
    border: 1px solid #ece9fd;
    border-radius: 8px;
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    color: #9088ad;

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      text-align: center;

      color: #000000;
      margin-bottom: 14px;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn-primary {
    background: linear-gradient(270.21deg, #a2bdff 0.19%, #6b38ff 97.23%);
    border-radius: 4px;
    padding: 16px;
    width: 163px;
    max-width: 100%;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
    margin: 11px auto 0 auto;
  }
}</style>
